<template>
  <div class="rank">
    <div class="head">
      <ul class="tabs">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ current: item.value == current }"
          @click="onItemClick(item)"
        >
          <span>{{ item.key }}</span>
          <el-divider
            v-if="index !== tabs.length - 1"
            direction="vertical"
          ></el-divider>
        </li>
      </ul>
    </div>
    <div class="rank">
      <table class="table" v-if="current === 1">
        <tr>
          <th>排名</th>
          <th>单位名称</th>
          <th>满意数</th>
          <th>评价数</th>
          <th>满意率</th>
        </tr>
        <tr v-for="item in pleaseList" :key="item.id">
          <td>{{ item.no }}</td>
          <td>{{ item.deptName }}</td>
          <td>{{ item.pleaseCount }}</td>
          <td>{{ item.backCount }}</td>
          <td>{{ item.pleaseRate }}%</td>
        </tr>
      </table>
      <table class="table" v-else>
        <tr>
          <th>排名</th>
          <th>单位名称</th>
          <th>答复数</th>
          <th>已受理</th>
          <th>答复率</th>
        </tr>
        <tr v-for="item in replyList" :key="item.id">
          <td>{{ item.no }}</td>
          <td>{{ item.deptName }}</td>
          <td>{{ item.replayCount }}</td>
          <td>{{ item.acceptCount }}</td>
          <td>{{ item.replayRate }}%</td>
        </tr>
      </table>
    </div>
    <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import { getDeptPleasePage, getDeptReplyPage } from "@/api/wzaskscensus";
export default {
  name: "rank",
  data() {
    return {
      current: 1,
      tabs: [
        {
          key: "满意度排行榜",
          value: 1,
        },
        {
          key: "答复率排行榜",
          value: 2,
        },
      ],
      currentPage: 1,
      currentSize: 999,
      total: 0,
      askList: [],
      pleaseList: [],
      replyList: [],
    };
  },
  watch: {
    current: {
      handler(val) {
        if (val === 1) {
          this.getDeptPleasePage();
        } else {
          this.getDeptReplyPage();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getDeptPleasePage() {
      const res = await getDeptPleasePage(this.currentPage, this.currentSize);
      // console.log(res)
      this.pleaseList = res.data.records.map((item, index) => ({
        ...item,
        no: (res.data.current - 1) * res.data.size + index + 1,
      }));
      this.total = res.data.total;
    },
    async getDeptReplyPage() {
      const res = await getDeptReplyPage(this.currentPage, this.currentSize);
      // console.log(res)
      this.replyList = res.data.records.map((item, index) => ({
        ...item,
        no: (res.data.current - 1) * res.data.size + index + 1,
      }));
      this.total = res.data.total;
    },
    onItemClick(item) {
      this.current = item.value;
      this.currentPage = 1;
      this.currentSize = 999;
    },
    handleSizeChange(val) {
      this.currentSize = val;
      if (this.current === 1) {
        this.getDeptPleasePage();
      } else {
        this.getDeptReplyPage();
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.current === 1) {
        this.getDeptPleasePage();
      } else {
        this.getDeptReplyPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rank {
  background: #ffffff;
  padding-bottom: 20px;
  .head {
    height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    .tabs {
      display: flex;
      align-items: center;
      padding: 0 20px;
      li {
        font-size: 16px;
        color: #90949e;
        padding: 14px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.current {
          font-weight: 700;
          color: #2a2b2e;
        }
      }
    }
  }
  .rank {
    padding: 20px;
    .table {
      width: 100%;

      text-align: center;
      th {
        font-size: 14px;
        color: #222222;
        line-height: 46px;
        background: #f9f9fa;
      }
      td {
        font-size: 14px;
        color: #333333;
        line-height: 36px;
      }
    }
  }
  .el-pagination {
    padding: 20px;
  }
}
</style>